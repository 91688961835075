<script setup>
import { goNextWebPage } from '@/assets/js/jsbridge';
const handelLink = (url) => {
  goNextWebPage({
    url: `${process.env.VUE_APP_URL}/agreement${url}`,
    title: url === '/user' ? '用户协议' : '隐私政策',
    titleIsTransparent: false,
  });
};
</script>
<template>
  <div class="welcome">
    <p>
      欢迎您使用亲Key键盘服务!为了更好地保障您的个人权益,在正式开启使用服务前,请您审慎阅读
      <a @click="handelLink('/user')">《亲Key用户协议》</a>、<a
        @click="handelLink('/privacy')"
        >《亲Key隐私协议》</a
      >，以便了解我们为您提供的服务内容及形式、使用本服务需遵守的规范,同时了解我们如何收集、使用、存储、保存及保护、对外提供您的个人信息以及您如何向我们行使您的法定权利。
    </p>
    <p>
      对于<a @click="handelLink('/user')">《亲Key用户协议》</a
      >,您点击同意即代表您已阅读并同意相关内容。
    </p>
    <p>
      对于<a @click="handelLink('/privacy')">《亲Key隐私协议》</a
      >,您点击同意仅代表您已知悉本服务提供的基本功能,并同意我们收集基本功能运行所需的必要个人信息,并不代表您已同意我们为提供附加功能收集非必要个人信息。对于非必要的个人信息处理,会在您开启具体附加服务前单独征求您的同意。
    </p>
    <p>如您同意以上内容,请点击"同意",正式开启服务!</p>
  </div>
</template>
<style lang="scss" scoped>
.welcome {
  padding: 30px;
  font-size: 32px;
  line-height: 46px;
  color: var(--main-text-color);
  font-family: PingFang SC;
  text-align: left;
  a,
  .text-blue {
    color: var(--light-text-color);
  }
}
</style>
