export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

export function isAndroid() {
  var u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
}

export function isiOS() {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

export function isWeixin() {
  var u = navigator.userAgent;
  return (
    u.match(/MicroMessenger/i) &&
    u
      .match(/MicroMessenger/i)
      .toString()
      .toLowerCase() == 'micromessenger'
  );
}

/**
 * 手机类型
 * */
export const typeLists = [
  { name: 'OPPO', label: 'OPPO手机使用教程' },
  { name: 'VIVO', label: 'VIVO手机使用教程' },
  { name: '华为', label: '华为手机使用教程' },
  { name: '小米', label: '小米手机使用教程' },
  { name: '荣耀', label: '荣耀手机使用教程' },
  { name: 'iPhone', label: 'iPhone手机使用教程' },
  { name: 'AndroidGame', label: 'Android手机游戏键盘使用教程' },
  { name: 'IosGame', label: 'iPhone手机游戏键盘使用教程' },
];
